import React, {useEffect} from "react";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hero1 from "../../img/slider-1.jpg";
// import hero2 from "../../img/slider-2.jpg";
import promo_video from "../../img/video/promo-video.mp4"
import promoBanner from "../../img/Chernyak_1200×628.png"
import {initTicketForEvent} from "../ticketforevent";


const Hero = () => {
   const {t} = useTranslation();

   useEffect(() => {
      initTicketForEvent();
   }, []);

   const onClick = (e) => {
      e.preventDefault();
   };

   const settings = {
      dots: false,
      arrows: false,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      fade: true,
   };

   // return (
   //    <>
   //       <section className="gauto-slider-area fix">
   //          <img src={promoBanner} alt="" className="promo-banner"/>
   //          <div className="slider-text chernyak">
   //             <a className="gauto-btn epts-cb-green2 tfe-embed-popup gauto-btn__event-3"
   //                data-alias="forum-millionario-alicante-08-11-23"
   //                data-lang="uk"
   //                target="_blank"
   //                href="https://forum-millionario-alicante-08-11-23.ticketforevent.com/uk/">
   //                {t("buy_ticket")}
   //             </a>
   //          </div>
   //       </section>
   //       <section className="gauto-slider-area fix mobile">
   //          <div className="slider-text chernyak">
   //             <a className="gauto-btn epts-cb-green2 tfe-embed-popup gauto-btn__event-3"
   //                data-alias="forum-millionario-alicante-08-11-23"
   //                data-lang="uk"
   //                target="_blank"
   //                href="https://forum-millionario-alicante-08-11-23.ticketforevent.com/uk/">
   //                {t("buy_ticket")}
   //             </a>
   //          </div>
   //       </section>
   //    </>
   //
   // );

   return (
      <section className="gauto-slider-area fix">
         <video autoPlay muted loop className='promo-video'>
            <source src={promo_video} type="video/mp4"/>
         </video>
         <div className="slider-text">
            {/*<h2>{t("hero_slide_title")}</h2>*/}
            {/*<p>{t("hero_slide_subtitle")}</p>*/}
            <Link to="/apply-for-short" className="gauto-btn">
               {t("apply_for")}
            </Link>
         </div>
      </section>
   );
};

export default Hero;
