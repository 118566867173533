import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import ApplyFor from "../../components/ApplyFor";
import Footer from "../../components/Footer";


const ApplyForPage = (props) => {
   const {t} = useTranslation();

   let page = {
      ApplyForFormLong: {
         pageTitle: t("promo_btn"),
         pagesub: t("promo_btn"),
      },
      ApplyForFormShort: {
         pageTitle: t("header-navigation.apply_for"),
         pagesub: t("header-navigation.apply_for"),
      }
   }

   return (
      <Fragment>
         <Header/>
         <PageTitle
            pageTitle={page[props.component].pageTitle}
            pagesub={page[props.component].pagesub}
         />
         <ApplyFor component={props.component}/>
         {/*<Footer/>*/}
      </Fragment>
   );
};

export default ApplyForPage
