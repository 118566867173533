import React, {useEffect} from "react";
import {initTicketForEvent} from "../../ticketforevent";

import slice1 from "../../../img/forum/event-2024-02-09/slice1.png"
import slice2btn from "../../../img/forum/event-2024-02-09/slice2btn.png"
import slice3 from "../../../img/forum/event-2024-02-09/slice3.png"
import slice4video from "../../../img/forum/event-2024-02-09/slice4video.png"
import slice5 from "../../../img/forum/event-2024-02-09/slice5.png"
import slice6btn from "../../../img/forum/event-2024-02-09/slice6btn.png"
import slice7 from "../../../img/forum/event-2024-02-09/slice7.png"
import slice8btn from "../../../img/forum/event-2024-02-09/slice8btn.png"
import video from "../../../img/forum/event-2024-02-09/video.mp4"


import slice1mob from "../../../img/forum/event-2024-02-09/mob/slice1mob.png"
import slice2mob_btn from "../../../img/forum/event-2024-02-09/mob/slice2mob_btn.png"
import slice3mob from "../../../img/forum/event-2024-02-09/mob/slice3mob.png"
import slice4mob_video from "../../../img/forum/event-2024-02-09/mob/slice4mob_video.png"
import slice5mob from "../../../img/forum/event-2024-02-09/mob/slice5mob.png"
import slice6mob_btn from "../../../img/forum/event-2024-02-09/mob/slice6mob_btn.png"
import slice7mob from "../../../img/forum/event-2024-02-09/mob/slice7mob.png"
import slice8mob_btn from "../../../img/forum/event-2024-02-09/mob/slice8mob_btn.png"
import slice9mob from "../../../img/forum/event-2024-02-09/mob/slice9mob.png"
import {useTranslation} from "react-i18next";


const ButtonForEvent = (props) => {
   const {t} = useTranslation();
   if (props.admin_telegram_link === true) {
      const tel_link = `${process.env.REACT_APP_ADMIN_TELEGRAM_LINK}`
      return (
         <a className="gauto-btn gauto-btn__event-3"
            target='_blank'
            href={tel_link}>
            Стати партнером
         </a>
      )
   } else {
      return (
         <a className="gauto-btn epts-cb-green2 tfe-embed-popup gauto-btn__event-3"
            data-alias="forum-millionario-09-02-24"
            data-lang="uk"
            target="_blank"
            href="https://forum-millionario-09-02-24.ticketforevent.com/uk/">
            {t("buy_ticket")}
         </a>
      )
   }
}


const Event2023November21 = () => {

   useEffect(() => {
      initTicketForEvent();
   }, []);

   return (
      <>
         <section className="section_70 forum event-2 event-2024-02-09">
            <div className="forum-invite-wrapper">
               <img src={slice1} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice2btn} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice3} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice4video} alt=""/>
               <video controls>
                  <source src={video} type="video/mp4"/>
                  Your browser does not support the video tag.
               </video>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice5} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
            <img src={slice6btn} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice7} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice8btn} alt=""/>
               <ButtonForEvent admin_telegram_link={false}/>
            </div>
         </section>
         <section className="section_70 forum mobile event-2 event-2024-02-09-mob">
            <div className="forum-invite-wrapper">
               <img src={slice1mob} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice2mob_btn} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice3mob} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice4mob_video} alt=""/>
               <video controls>
                  <source src={video} type="video/mp4"/>
                  Your browser does not support the video tag.
               </video>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice5mob} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice6mob_btn} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice7mob} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice8mob_btn} alt=""/>
               <ButtonForEvent admin_telegram_link={false}/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice9mob} alt=""/>
            </div>
         </section>
      </>
   );
};

export default Event2023November21;
