import React from "react";
import { useTranslation } from "react-i18next";
import {Col} from "react-bootstrap";


function SuccessMessage({show_success}){
    const { t } = useTranslation();
    if (show_success){
        return (
            <Col className={'form-success'}>{t('form_submit_success')}</Col>
        )
    }
    return null;
}

export default SuccessMessage;
