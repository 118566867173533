import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {Col, Container, Row, Dropdown} from "react-bootstrap";
import {
   FaPhoneAlt,
   FaSignInAlt,
   FaUserAlt,
   FaSearch,
   FaGlobe,
} from "react-icons/fa";
import MobileMenu from "../../components/MobileMenu";

import Logo from "../../img/logo.png";
import globe from "../../img/globe.png";
import clock from "../../img/clock.png";
import "flag-icon-css/css/flag-icons.min.css";

const languages = [
   // {
   //   code: "fr",
   //   name: "Français",
   //   country_code: "fr",
   // },
   // {
   //   code: "en",
   //   name: "English",
   //   country_code: "gb",
   // },
   // {
   //   code: "pt",
   //   name: "Português",
   //   country_code: "pt",
   // },
   {
      code: "uk",
      name: "українська",
      country_code: "ua", // iso 3166-1-alpha-2 code
   },
];

const Header = () => {
   const SubmitHandler = (e) => {
      e.preventDefault();
   };

   const onClick = (e) => {
      e.preventDefault();
   };

   const {t} = useTranslation();

   return (
      <Fragment>
         <section className="gauto-header-top-area">
            <Container>
               <Row>
                  <Col md={6}>
                     <div className="header-top-left">
                        <p>
                           {t("need_help")} <FaPhoneAlt/> {t("call")}: +34 613 705 442
                        </p>
                     </div>
                  </Col>
                  <Col md={6}>
                     <div className="header-top-right">
                        {/*<Link to="/login">*/}
                        {/*    <FaSignInAlt/>*/}
                        {/*    {t("login")}*/}
                        {/*</Link>*/}
                        {/*<Link to="/register">*/}
                        {/*    <FaUserAlt/>*/}
                        {/*    {t("register")}*/}
                        {/*</Link>*/}
                        <Dropdown>
                           <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <FaGlobe/> {t("language")}
                           </Dropdown.Toggle>

                           <Dropdown.Menu>
                              {languages.map(({code, name, country_code}) => (
                                 <Dropdown.Item
                                    eventKey={name}
                                    key={country_code}
                                    to="/"
                                    onClick={() => i18next.changeLanguage(code)}
                                 >
                                                <span
                                                   className={`flag-icon flag-icon-${country_code}`}
                                                ></span>
                                    {" "}
                                    {name}
                                 </Dropdown.Item>
                              ))}
                           </Dropdown.Menu>
                        </Dropdown>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
         <header className="gauto-main-header-area">
            <Container>
               <Row>
                  <Col md={3}>
                     <div className="site-logo">
                        <a href="/">
                           <img src={Logo} alt="gauto"/>
                        </a>
                     </div>
                  </Col>
                  <Col lg={6} sm={9}>
                     <div className="header-promo">
                        <div className="single-header-promo">
                           <div className="header-promo-icon">
                              <img src={globe} alt="globe"/>
                           </div>
                           <div className="header-promo-info">
                              <p className='highlight'>{t("alicante_spain")}</p>
                           </div>
                        </div>
                        <div className="single-header-promo">
                           <div className="header-promo-icon">
                              <img src={clock} alt="clock"/>
                           </div>
                           <div className="header-promo-info">
                              <p className='highlight'>{t("monday_friday")}</p>
                              <p>9:00 - 21:00</p>
                           </div>
                        </div>
                     </div>
                  </Col>
                  <div className="col-lg-3">
                     <div className="header-action">
                        <Link to="/contact">
                           <FaPhoneAlt/> {t("request_call")}
                        </Link>
                     </div>
                  </div>
               </Row>
            </Container>
         </header>
         <section className="gauto-mainmenu-area">
            <Container>
               <Row>
                  <Col lg={9}>
                     <div className="mainmenu">
                        <nav>
                           <ul id="gauto_navigation">
                              <li>
                                 <Link to="/about">{t("header-navigation.about")}</Link>
                              </li>
                              <li>
                                 <Link to="/apply-for">{t("header-navigation.anketa")}</Link>
                              </li>
                              <li>
                                 <Link to="/contact">{t("header-navigation.contact_us")}</Link>
                              </li>
                              <li>
                                 <Link to="/page-in-dev">{t("header-navigation.mentors")}</Link>
                              </li>
                              <li>
                                 <Link to="#">{t("header-navigation.forum")}</Link>
                                 <ul>
                                    <li>
                                       <Link to="/forum/2023-october-01">
                                          01 жовтня 2023 р.
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="/forum/2023-november-01">
                                          01 листопада 2023 р.
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="/forum/2023-november-21">
                                          21 листопада 2023 р.
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="/forum/2024-february-09">
                                          9 лютого 2024 р.
                                       </Link>
                                    </li>
                                 </ul>
                              </li>
                              <li>
                                 <Link to="/page-in-dev">{t("header-navigation.club_card")}</Link>
                              </li>
                           </ul>
                        </nav>
                     </div>
                  </Col>
                  <Col lg={3} sm={12}>
                     <div className="main-search-right">
                        <MobileMenu t={t}/>
                        <div className="header-cart-box">
                           <div className="login dropdown">
                              {/*<Link to="/cart" className="cart-icon" id="dropdownMenu1">*/}
                              {/*    <span>2</span>*/}
                              {/*</Link>*/}
                           </div>
                        </div>
                        {/*<div className="search-box">*/}
                        {/*    <form onSubmit={SubmitHandler}>*/}
                        {/*        <input type="search" placeholder="Search9"/>*/}
                        {/*        <button type="submit">*/}
                        {/*            <FaSearch/>*/}
                        {/*        </button>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
      </Fragment>
   );
};

export default Header;
