import ApplyForForm from "./ApplyForForm";


class ApplyForFormLong extends ApplyForForm {

   constructor(props) {
      super(props);
   }
}

export default ApplyForFormLong
