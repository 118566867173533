import React, {Component, Fragment} from "react";
import { useTranslation } from "react-i18next";
import {useSearchParams, useNavigate} from "react-router-dom";
import {Container, Row} from "react-bootstrap";
import SuccessMessage from "../SuccessMessage";
import ApplyForFormLong from "./ApplyForFormLong";
import ApplyForFormShort from "./ApplyForFormShort";


const Components = {
   ApplyForFormLong: ApplyForFormLong,
   ApplyForFormShort: ApplyForFormShort
}


const ApplyFor = (props) => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const show_success = searchParams.get('actions') === 'success';
    const ApplyForForm = Components[props.component]
    return (
        <section className="checkout-page-area section_70 apply-for">
            <Container>
                <Row>
                    <SuccessMessage show_success={show_success}/>
                    <ApplyForForm
                        show_form={!show_success}
                        t={t}
                        searchParams={searchParams}
                        navigate ={useNavigate()}
                    />
                </Row>
            </Container>
        </section>
    )
}


export default ApplyFor
