import React, {useEffect} from "react";
import {initTicketForEvent} from "../../ticketforevent";
import {ButtonForEvent20231121} from "../button";

import slice1 from "../../../img/forum/event-2023-11-21/Slice1.png"
import slice2btn from "../../../img/forum/event-2023-11-21/Slice2btn.png"
import slice3 from "../../../img/forum/event-2023-11-21/Slice3.png"
import slice4btn from "../../../img/forum/event-2023-11-21/Slice4btn.png"
import slice5 from "../../../img/forum/event-2023-11-21/Slice5.png"
import slice5_1 from "../../../img/forum/event-2023-11-21/Slice5.1.png"
import slice5_2 from "../../../img/forum/event-2023-11-21/Slice5.2.png"
import slice6btn from "../../../img/forum/event-2023-11-21/Slice6btn.png"
import slice7 from "../../../img/forum/event-2023-11-21/Slice7.png"
import slice8btn from "../../../img/forum/event-2023-11-21/Slice8btn.png"

import mob_slice1 from "../../../img/forum/event-2023-11-21/mob/mobslice1.png"
import mob_slice2btn from "../../../img/forum/event-2023-11-21/mob/mobslice2btn.png"
import mob_slice3 from "../../../img/forum/event-2023-11-21/mob/mobslice3.png"
import mob_slice4btn from "../../../img/forum/event-2023-11-21/mob/mobslice4btn.png"
import mob_slice5 from "../../../img/forum/event-2023-11-21/mob/mobslice5.png"
import mob_slice5_1 from "../../../img/forum/event-2023-11-21/mob/mobslice5.1.png"
import mob_slice6btn from "../../../img/forum/event-2023-11-21/mob/mobslice6btn.png"
import mob_slice7 from "../../../img/forum/event-2023-11-21/mob/mobslice7.png"
import mob_slice8btn from "../../../img/forum/event-2023-11-21/mob/mobslice8btn.png"


const Event2023November21 = () => {

   useEffect(() => {
      initTicketForEvent();
   }, []);

   return (
      <>
         <section className="section_70 forum event-2">
            <div className="forum-invite-wrapper">
               <img src={slice1} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice2btn} alt=""/>
               <ButtonForEvent20231121/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice3} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice4btn} alt=""/>
               <ButtonForEvent20231121/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice5} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice5_1} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice5_2} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice6btn} alt=""/>
               <ButtonForEvent20231121/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice7} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice8btn} alt=""/>
               <ButtonForEvent20231121 admin_telegram_link={true}/>
            </div>
         </section>
         <section className="section_70 forum mobile event-2">
            <div className="forum-invite-wrapper">
               <img src={mob_slice1} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice2btn} alt=""/>
               <ButtonForEvent20231121/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice3} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice4btn} alt=""/>
               <ButtonForEvent20231121/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice5} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice5_1} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice6btn} alt=""/>
               <ButtonForEvent20231121/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice7} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={mob_slice8btn} alt=""/>
               <ButtonForEvent20231121 admin_telegram_link={true}/>
            </div>
         </section>
      </>
   );
};

export default Event2023November21;
