import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from "react-bootstrap";
import {
   FaFacebook,
   FaLinkedin,
   FaPaperPlane,
   FaPinterest,
   FaSkype,
   FaTwitter,
   FaVimeo,
} from "react-icons/fa";


const Contact = () => {
   const {t} = useTranslation();

   const SubmitHandler = (e) => {
      e.preventDefault();
   };

   const onClick = (e) => {
      e.preventDefault();
   };

   return (
      <section className="gauto-contact-area section_70">
         <Container>
            <Row>
               <Col lg={7} md={6}>
                  <div className="contact-left">
                     <h3>{t("contact_page.get_touch")}</h3>
                     <form onSubmit={SubmitHandler}>
                        <Row>
                           <Col md={6}>
                              <div className="single-contact-field">
                                 <input type="text" placeholder={t("contact_page.name")}/>
                              </div>
                           </Col>
                           <Col md={6}>
                              <div className="single-contact-field">
                                 <input
                                    type="email"
                                    placeholder={t("contact_page.email")}
                                 />
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col md={6}>
                              <div className="single-contact-field">
                                 <input
                                    type="text"
                                    placeholder={t("contact_page.subject")}
                                 />
                              </div>
                           </Col>
                           <Col md={6}>
                              <div className="single-contact-field">
                                 <input type="tel" placeholder={t("contact_page.phone")}/>
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col md={12}>
                              <div className="single-contact-field">
                      <textarea
                         placeholder={t("contact_page.msg")}
                         defaultValue={""}
                      />
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col md={12}>
                              <div className="single-contact-field">
                                 <button type="submit" className="gauto-theme-btn">
                                    <FaPaperPlane/> {t("contact_page.send")}
                                 </button>
                              </div>
                           </Col>
                        </Row>
                     </form>
                  </div>
               </Col>
               <Col lg={5} md={6}>
                  <div className="contact-right">
                     <h3>{t("contact_page.info_title")} </h3>
                     <div className="contact-details">
                        <p>
                           <i className="fa fa-map-marker"/>
                           Аліканте, Іспанія
                        </p>
                        <div className="single-contact-btn">
                           <h4>{t("contact_page.info_email")}</h4>
                           <a href="mailto:general@millionario.es">general@millionario.es</a>
                        </div>
                        <div className="single-contact-btn">
                           <h4>{t("contact_page.info_call")}</h4>
                           <a href="tel:+34613705442">+34 613 705 442</a>
                        </div>
                        <div className="social-links-contact">
                           <h4>{t("contact_page.info_follow")}</h4>
                           <ul>
                              <li>
                                 <Link to="/" onClick={onClick}>
                                    <FaFacebook/>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/" onClick={onClick}>
                                    <FaTwitter/>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/" onClick={onClick}>
                                    <FaLinkedin/>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/" onClick={onClick}>
                                    <FaPinterest/>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/" onClick={onClick}>
                                    <FaSkype/>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/" onClick={onClick}>
                                    <FaVimeo/>
                                 </Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </Col>
            </Row>
            <Row className="mt-5">
               <Col>
                  <div>
                     <h3>Політика щодо обробки персональних даних</h3>
                     <div>
                        1. Загальні положення
                        Справжня політика обробки персональних даних складена відповідно до вимог Закону України "Про
                        захист персональних даних" і визначає порядок обробки персональних даних та заходи щодо
                        забезпечення безпеки персональних даних, що вживаються бізнес-клубом Мillionario (далі –
                        Оператор), з місцезнаходженням за адресою: 65089, м. Одеса, вул. Академіка Вільямса, буд. 60,
                        корп. 2, кв. 46. Україна
                        1.1. Оператор ставить своєю найважливішою метою і умовою здійснення своєї діяльності дотримання
                        прав та свобод людини при обробці його персональних даних, в тому числі захисту прав на
                        недоторканність приватного життя, особисту та сімейну таємницю.
                        1.2. Справжня політика Оператора щодо обробки персональних даних (далі - Політика)
                        застосовується до всієї інформації, яку Оператор може отримати про відвідувачів веб-сайту
                        millionario.es (далі – Користувач). Тому, перш ніж надавати ваші персональні дані, будь ласка,
                        прочитайте наступну інформацію, яка пояснює, чому ми збираємо ваші дані, як ми їх
                        використовуємо, які ваші права. Будьте обережні: якщо ви неповнолітній, вам слід попросити
                        батьків підтвердити реєстрацію.

                        2. Оператор може обробляти такі персональні дані Користувача
                        2.1. Прізвище, ім'я, по батькові;
                        2.2. Электронна адреса;
                        2.3. Номери телефонів;
                        2.4. Також на сайті відбувається збір та обробка знеособлених даних про відвідувачів за
                        допомогою сервісів інтернет-статистики (Google Analytics, Яндекс Метрика та інші).
                        2.5. Перераховані вище дані далі по тексту Політики об'єднані загальним поняттям Персональні
                        дані.

                        3. Цілі обробки персональних даних
                        3.1. Мета обробки персональних даних Користувача - інформування Користувача за допомогою
                        відправки електронних листів; надання доступу Користувачеві до сервісів, інформації та / або
                        матеріалам, що містяться на веб-сайті.
                        3.2. Також Оператор має право направляти Користувачеві повідомлення про нові продукти та
                        послуги, спеціальні пропозиції та різні події. Користувач завжди може відмовитися від отримання
                        інформаційних повідомлень, перейшовши на відповідну фразу в кінці кожного листа від Оператора.
                        3.3. Знеособлені дані користувачів, що збираються за допомогою сервісів інтернет-статистики,
                        служать для збору інформації про дії користувачів на сайті, поліпшення якості сайту та його
                        змісту.

                        4. Правові підстави обробки персональних даних
                        Оператор обробляє персональні дані Користувача тільки в разі їх заповнення та / або відправки
                        Користувачем самостійно через спеціальні форми, розташовані на сайті millionario.es. Заповнюючи
                        відповідні форми та / або відправляючи свої персональні дані Оператору, Користувач висловлює
                        свою згоду з цією політикою.

                        5. Порядок збору, зберігання, передачі та інших видів обробки персональних даних
                        Безпека персональних даних, які обробляються Оператором, забезпечується шляхом реалізації
                        правових, організаційних і технічних заходів, необхідних для виконання в повному обсязі вимог
                        чинного законодавства у сфері захисту персональних даних.
                        5.1. Оператор забезпечує збереження персональних даних і вживає всіх можливих заходів, що
                        виключають доступ до персональних даних неуповноважених осіб.
                        5.2. Персональні дані Користувача ніколи, ні за яких умов не будуть передані третім особам, за
                        винятком випадків, пов'язаних з виконанням чинного законодавства.
                        5.3. У разі виявлення неточностей у персональних даних, Користувач може актуалізувати їх
                        самостійно, шляхом подання відповідного Оператору повідомлення на адресу електронної пошти
                        Оператора legend77@ukr.net з поміткою "Актуалізація персональних даних".
                        5.4. Термін обробки персональних даних є необмеженим. Користувач може в будь-який момент
                        відкликати свою згоду на обробку персональних даних, направивши Оператору повідомлення за
                        допомогою електронної пошти на електронну адресу Оператора legend77@ukr.net з поміткою
                        "Відкликання згоди на обробку персональних даних".

                        6. Заключні положення
                        6.1. Користувач може отримати будь-які роз'яснення з потрібних питань, що стосуються обробки
                        його персональних даних, звернувшись до Оператору за допомогою електронної пошти
                        legend77@ukr.net.
                        6.2. В даному документі будуть відображені будь-які зміни політики обробки персональних даних
                        Оператором. Політика діє безстроково до заміни її новою версією.

                     </div>
                  </div>
               </Col>
            </Row>
            <Row className="mt-5">
               <Col>
                  <div>
                     <h3>Умови оплати</h3>
                     <div>
                        Ласкаво просимо до бізнес-клубу Millionario!
                        Нижче ви знайдете основні правила та умови, які регулюють надання наших унікальних
                        бізнес-послуг, а також інформацію про способи оплати.
                        1. Надання послуг
                        • Послуги: Millonario пропонує різноманітні бізнес-послуги, які докладно описані на нашому
                        сайті.
                        • Терміни та порядок: Кожна послуга має власні строки та порядок надання, які обговорюються
                        індивідуально з кожним клієнтом.
                        • Відповідальність: Ми прагнемо надати нашим клієнтам найвищий рівень сервісу. У разі виникнення
                        питань чи проблем наша команда готова допомогти вам у їх вирішенні.

                        2. Оплата
                        Для вашої зручності ми надаємо різноманітні способи оплати:
                        • Онлайн-платежі: Millonario приймає до оплати кредитні та дебетові картки через безпечну
                        систему онлайн-платежів.
                        • Банківський переказ: Ви можете здійснити банківський переказ на вказаний нами рахунок.
                        Реквізити для переказу надані нижче.
                        • Готівка: У деяких випадках ми також приймаємо готівку. Зв'яжіться з нами, щоб дізнатися, як
                        цей спосіб оплати може бути застосований до вашої послуги.

                        3. Умови та порядок повернення

                        • Помилкові транзакції: Якщо в результаті наших помилок були зняті кошти з вашого рахунку
                        неправильно або в непередбаченому обсязі, ми одразу зробимо корекцію і повернемо ваші кошти.
                        • Скасування послуги: У випадку, якщо ви вирішите відмовитися від наших послуг до початку
                        надання, вам буде надано повне повернення коштів, за вирахуванням можливих комісій, якщо такі
                        застосовуються.

                        Порядок повернення
                        • Зв'яжіться з нами: Щоб ініціювати процес повернення коштів, зверніться до нашої служби
                        підтримки. Переконайтеся, що ви надаєте всю необхідну інформацію про транзакцію.
                        • Розгляд заявки: Наші фахівці проведуть перевірку вашої заявки та розглянуть її у найкоротший
                        термін.
                        • Повернення коштів: Якщо ваша заявка на повернення коштів схвалена, ми повернемо ваші кошти
                        згідно з застосовними правилами та способами оплати.

                        Терміни повернення
                        Терміни повернення коштів можуть різнитися залежно від способу оплати та інших чинників. Ми
                        гарантуємо, що ми зробимо все можливе для обробки вашої заявки в найкоротші терміни.
                        У разі виникнення запитань або необхідності розпочати процедуру повернення, зв'яжіться з нашою
                        службою підтримки. Ми завжди готові допомогти вам у цьому процесі та забезпечити ваше побажання.

                        Ми цінуємо ваш вибір Millionario для реалізації ваших бізнес-цілей. Наша мета – забезпечити вас
                        найкращими послугами та підтримкою на кожному етапі. Якщо у вас виникли запитання або потрібна
                        додаткова інформація, не соромтеся зв'язатися з нашою службою підтримки.
                        Дякуємо вам за вибір Millionario. Ми готові допомогти вам досягти фінансового успіху!

                        Юридична адреса:
                        65089, м. Одеса,
                        вул. Академіка Вільямса, буд. 60, корп. 2, кв. 46

                        Контакти:
                        +34 613 705 442
                        legend77@ukr.net

                        Реквізити:
                        ФОП Стратулат Надія Михайлівна
                        ІПН: 3052825540
                        IBAN: UA983052990000026002034909060
                        Банк: АТ КБ "ПРИВАТБАНК"

                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
      </section>
   );
};

export default Contact;
