import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from "react-bootstrap";
import {
   FaFacebookF,
   FaTwitter,
   FaLinkedinIn,
   FaInstagram,
} from "react-icons/fa";

import img1 from "../../img/members/member1.jpg";
import img2 from "../../img/members/member2.jpg";
import img3 from "../../img/members/member3.jpg";
import img4 from "../../img/members/member4.jpg";
import img5 from "../../img/members/member5.jpg";
import img6 from "../../img/members/member6.jpg";
import img7 from "../../img/members/member7.jpg";
import img8 from "../../img/members/member8.jpg";


const Team = () => {
   const {t} = useTranslation();

   const onClick = (e) => {
      e.preventDefault();
   };

   return (
      <section className="gauto-driver-area section_70">
         <Container>
            <Row>
               <Col md={12}>
                  <div className="site-heading">
                     <h4>{t("experts")}</h4>
                     <h2>{t("our_members")}</h2>
                  </div>
               </Col>
            </Row>
            <Row>
               {/*<Col lg={3} sm={6}>*/}
               {/*   <div className="single-driver">*/}
               {/*      <div className="driver-image">*/}
               {/*         <img src={img1} alt="driver 1"/>*/}
               {/*         <div className="hover">*/}
               {/*           <ul className="social-icon">*/}
               {/*             <li>*/}
               {/*               <Link to="/" onClick={onClick}>*/}
               {/*                 <FaFacebookF />*/}
               {/*               </Link>*/}
               {/*             </li>*/}
               {/*             <li>*/}
               {/*               <Link to="/" onClick={onClick}>*/}
               {/*                 <FaTwitter />*/}
               {/*               </Link>*/}
               {/*             </li>*/}
               {/*             <li>*/}
               {/*               <Link to="/" onClick={onClick}>*/}
               {/*                 <FaLinkedinIn />*/}
               {/*               </Link>*/}
               {/*             </li>*/}
               {/*             <li>*/}
               {/*               <Link to="/" onClick={onClick}>*/}
               {/*                 <FaInstagram />*/}
               {/*               </Link>*/}
               {/*             </li>*/}
               {/*           </ul>*/}
               {/*         </div>*/}
               {/*      </div>*/}
               {/*      <div className="driver-text">*/}
               {/*         <div className="driver-name">*/}
               {/*            <Link to="/">*/}
               {/*               <h3>Олена Суєтова</h3>*/}
               {/*            </Link>*/}
               {/*            <p className='heading'>Засновник бізнес клубу Millionario</p>*/}
               {/*            <p className='description'>Філософія життя: творити добро і покращувати світ.*/}
               {/*               Волонтер з 2000 року, встановила єдиний у світі пам'ятний знак Пам'ятник Волонтерам, на*/}
               {/*               Сквері волонтерів у м.Київ, пр. Науки 22.*/}
               {/*               Маркетолог з 15-річним досвідом.*/}
               {/*               Входить до списку 100 впливових людей України.*/}
               {/*               Киянка року 2015 р.*/}
               {/*               Співвласнiк турагентства Coral travel Київ(франшиза)*/}
               {/*               Засновник бізнесу жіночої білизни "Бузок".</p>*/}
               {/*         </div>*/}
               {/*      </div>*/}
               {/*   </div>*/}
               {/*</Col>*/}
               <Col lg={3} sm={6}>
                  <div className="single-driver">
                     <div className="driver-image">
                        <img src={img3} alt="driver 1"/>
                     </div>
                     <div className="driver-text">
                        <div className="driver-name">
                           <Link to="/">
                              <h3>Любомир</h3>
                           </Link>
                           <p className='heading'>General Manager</p>
                           <p className='description'>
                              Філософія життя: якщо не пробувати зробити більше ніж можливо, то й ніколи не зробиш все
                              те, на що здатен.
                              11 років в банківській сфері в сегменті малого, середнього та корпоративнорго бізнесу,
                              керівником відділень.
                              Співвласник равликової ферми "Ukrainian Snail Farm"
                              Засновник стартапу Benidorm.info
                           </p>
                        </div>
                     </div>
                  </div>
               </Col>
               {/*<Col lg={3} sm={6}>*/}
               {/*   <div className="single-driver">*/}
               {/*      <div className="driver-image">*/}
               {/*         <img src={img4} alt="driver 1"/>*/}
               {/*      </div>*/}
               {/*      <div className="driver-text">*/}
               {/*         <div className="driver-name">*/}
               {/*            <Link to="/">*/}
               {/*               <h3>Юлія Рожкова</h3>*/}
               {/*            </Link>*/}
               {/*            <p className='heading'>Seo & Partner</p>*/}
               {/*            <p className='description'>*/}
               {/*               Юрист із 20-річним стажем.*/}
               {/*               Спеціаліст у галузі цивільного та кримінального права. Маю досвід представлення інтересів*/}
               {/*               держави та клієнтів у судах.*/}
               {/*               Співзасновниця кількох інтернет платформ в оптовій торгівлі.*/}
               {/*               Успішно адаптую минулий досвід для розвитку бізнесу та досягнення результатів.*/}
               {/*            </p>*/}
               {/*         </div>*/}
               {/*      </div>*/}
               {/*   </div>*/}
               {/*</Col>*/}
               <Col lg={3} sm={6}>
                  <div className="single-driver">
                     <div className="driver-image">
                        <img src={img2} alt="driver 1"/>
                     </div>
                     <div className="driver-text">
                        <div className="driver-name">
                           <Link to="/">
                              <h3>Руслан</h3>
                           </Link>
                           <p className='heading'>Учасник клубу</p>
                           <p className='description'>
                              Засновник бренду дитячого взуття Weestep. (Виробництво та дистриб'юція) 25 років досвіду у
                              створенні кращих команд, досвiд у міжнародній b2b торгівлі. Філософія життя: Хобі- це те,
                              що приносить задоволення, а задоволення, приносить допомогу іншим)
                           </p>
                        </div>
                     </div>
                  </div>
               </Col>
               {/*<Col lg={3} sm={6}>*/}
               {/*   <div className="single-driver">*/}
               {/*      <div className="driver-image">*/}
               {/*         <img src={img5} alt="driver 1"/>*/}
               {/*      </div>*/}
               {/*      <div className="driver-text">*/}
               {/*         <div className="driver-name">*/}
               {/*            <Link to="/">*/}
               {/*               <h3>Ольга</h3>*/}
               {/*            </Link>*/}
               {/*            <p className='heading'>Учасник клубу</p>*/}
               {/*            <p className='description'>*/}
               {/*               Ольга – професіонал у продажах з 20-річним досвідом. 10 років працювала над розвитком*/}
               {/*               компанії "Цитрус", втілюючи в життя нові концепції магазинів.*/}
               {/*               Після успішної кар'єри в "Цитрус", пройшовши шлях від продавця до CEO, займалася*/}
               {/*               консалтингом, співпрацюючи з такими компаніями як Дніпро М, Вухоком, Goodki та ін.*/}
               {/*               В Іспанії є засновник Clanrent – сервісу оренди преміум-класу.*/}
               {/*               Життєва філософія полягає в постійному розвитку, вірі в свої сили та прагненні до*/}
               {/*               відкриття нових можливостей.*/}
               {/*            </p>*/}
               {/*         </div>*/}
               {/*      </div>*/}
               {/*   </div>*/}
               {/*</Col>*/}
               {/*<Col lg={3} sm={6}>*/}
               {/*   <div className="single-driver">*/}
               {/*      <div className="driver-image">*/}
               {/*         <img src={img6} alt="driver 1"/>*/}
               {/*      </div>*/}
               {/*      <div className="driver-text">*/}
               {/*         <div className="driver-name">*/}
               {/*            <Link to="/">*/}
               {/*               <h3>Павел</h3>*/}
               {/*            </Link>*/}
               {/*            <p className='heading'>Учасник клубу</p>*/}
               {/*            <p className='description'>*/}
               {/*               Павло, учасник клубу*/}
               {/*               Засновник двох маркетплейсів, дистриб'ютор європійських товарів на український ринок,*/}
               {/*               15-річний досвід у Е-коммерс торгівлі.*/}
               {/*            </p>*/}
               {/*         </div>*/}
               {/*      </div>*/}
               {/*   </div>*/}
               {/*</Col>*/}
               <Col lg={3} sm={6}>
                  <div className="single-driver">
                     <div className="driver-image">
                        <img src={img7} alt="driver 1"/>
                     </div>
                     <div className="driver-text">
                        <div className="driver-name">
                           <Link to="/">
                              <h3>Олексій</h3>
                           </Link>
                           <p className='heading'>Учасник клубу</p>
                           <p className='description'>
                              Засновник: FeelWood - виробництво та дистрибуція дитячих іграшок з дерева, RennetProdukt -
                              виробництво молокозгортальних ферментів. 20-річний досвід у В2В продажах власної
                              продукції, мережа роздрібних магазинів. В Іспанії засновник компанії з Е-коммерс торгівлі
                           </p>
                        </div>
                     </div>
                  </div>
               </Col>
               <Col lg={3} sm={6}>
                  <div className="single-driver">
                     <div className="driver-image">
                        <img src={img8} alt="driver 1"/>
                     </div>
                     <div className="driver-text">
                        <div className="driver-name">
                           <Link to="/">
                              <h3>Олександр</h3>
                           </Link>
                           <p className='heading'>Учасник клубу</p>
                           <p className='description'>
                              Відкриваю двері до сервісу мрії з обслуговування автомобілів від дітейлінгу до
                              поглибленого технічного ремонту. 20-річний досвід в автомобільному бізнесі, від світової
                              логістики автомобілів та запчастин, до автоматичних мийок
                           </p>
                        </div>
                     </div>
                  </div>
               </Col>

            </Row>
            {/*<Row>*/}
            {/*   <Col md={12}>*/}
            {/*      <div className="load-more">*/}
            {/*         <Link to="/" onClick={onClick} className="gauto-btn">*/}
            {/*            {t("more_member")}*/}
            {/*         </Link>*/}
            {/*      </div>*/}
            {/*   </Col>*/}
            {/*</Row>*/}
         </Container>
      </section>
   );
};

export default Team;
