import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from "react-bootstrap";
import {
   FaHeart,
   FaFacebookF,
   FaTwitter,
   FaLinkedinIn,
   FaSkype,
   FaPaperPlane,
} from "react-icons/fa";

import logo from "../../img/footer-logo.png";
import img1 from "../../img/post-thumb-3.jpg";
import img2 from "../../img/post-thumb-2.jpg";
import img3 from "../../img/post-thumb-1.jpg";


const Footer = () => {
   const {t} = useTranslation();

   const onClick = (e) => {
      e.preventDefault();
   };

   const SubmitHandler = (e) => {
      e.preventDefault();
   };

   return (
      <footer className="gauto-footer-area">
         <div className="footer-top-area">
            <Container>
               <Row>
                  <Col lg={4}>
                     <div className="single-footer">
                        <div className="footer-logo">
                           <Link to="/">
                              <img src={logo} alt="footer-logo"/>
                           </Link>
                        </div>
                        <p>
                           Millionario: іспанська ДНК для українського бізнесу
                           Бізнес-клуб Міліонаріо - це перша українська бізнес-діаспора в Іспанії!
                        </p>
                     </div>
                  </Col>
                  <Col lg={4}>
                     <div className="single-footer quick_links">
                        <h3>{t("footer.quick_links")}</h3>
                        <ul className="quick-links">
                           <li>
                              <Link to="/" onClick={onClick}>
                                 {" "}
                                 {t("footer.about_us")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 {t("footer.our_service")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 {t("footer.case_studies")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 {t("footer.contact_us")}
                              </Link>
                           </li>
                        </ul>
                        <ul className="quick-links">
                           <li>
                              <Link to="/" onClick={onClick}>
                                 {t("footer.testimonials")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 {t("footer.privacy")}
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 {t("footer.latest_news")}
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
         <div className="footer-bottom-area">
            <Container>
               <Row>
                  <Col md={6}>
                     <div className="copyright">
                        <p>
                           Design With <FaHeart/> from{" "}
                           <Link to="/" onClick={onClick}>
                              Themescare
                           </Link>
                        </p>
                     </div>
                  </Col>
                  <Col md={6}>
                     <div className="footer-social">
                        <ul>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 <FaFacebookF/>
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 <FaTwitter/>
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 <FaLinkedinIn/>
                              </Link>
                           </li>
                           <li>
                              <Link to="/" onClick={onClick}>
                                 <FaSkype/>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
      </footer>
   );
};

export default Footer;
