import React from "react";
import slice_1 from "../../../img/forum/event-2023-11-01/slice_1.jpg"
import slice_2 from "../../../img/forum/event-2023-11-01/slice_2.jpg"
import slice_3 from "../../../img/forum/event-2023-11-01/slice_3.jpg"
import slice_4 from "../../../img/forum/event-2023-11-01/slice_4.jpg"
import slice_5 from "../../../img/forum/event-2023-11-01/slice_5.jpg"
import slice_1_mob from "../../../img/forum/event-2023-11-01/mob/slice_1_mob.jpg"
import slice_2_mob from "../../../img/forum/event-2023-11-01/mob/slice_2_mob.jpg"
import slice_3_mob from "../../../img/forum/event-2023-11-01/mob/slice_3_mob.jpg"
import slice_4_mob from "../../../img/forum/event-2023-11-01/mob/slice_4_mob.jpg"
import slice_5_mob from "../../../img/forum/event-2023-11-01/mob/slice_5_mob.jpg"
import {useTranslation} from "react-i18next";


const ButtonForEvent = () => {
   const {t} = useTranslation();
   return (
      <a className="gauto-btn"
         href="https://secure.wayforpay.com/qr/qcb1a7c959064">
         {t("buy_ticket")}
      </a>
   )
}

const Event2023November01 = () => {


   return (
      <>
         <section className="section_70 forum event-2">
            <div className="forum-invite-wrapper">
               <img src={slice_1} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_5} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_2} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_4} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_3} alt=""/>
            </div>
         </section>
         <section className="section_70 forum mobile event-2">
            <div className="forum-invite-wrapper">
               <img src={slice_1_mob} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_2_mob} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_3_mob} alt=""/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_4_mob} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={slice_5_mob} alt=""/>
            </div>
         </section>
      </>
   );
};

export default Event2023November01;
