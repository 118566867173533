import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";
import axios from "axios";
import img2 from "../../img/master-card.jpg";
import img3 from "../../img/paypal.jpg";


class ApplyForForm extends Component {

   constructor(props) {
      super(props);
      this.link = `${process.env.REACT_APP_API_LINK}/api/messages/`
      this.state = {
         formFields: {},
         fields_errors: {},
         form_errors: [],
      };
   }

   handleSubmit = (e, item) => {
      e.preventDefault();
      let form_errors = [];
      this.setState({form_errors});
      axios
         .post(this.link, item)
         // .post(`${process.env.REACT_APP_API_LINK}/api/messages/`, item)
         .then((res) => {
            this.props.navigate('/apply-for/?actions=success', {replace: true});
         })
         .catch((error) => { // error is handled in catch block
            if (error.response) { // status code out of the range of 2xx
               // console.log("Status :" + error.response.status);
               if (error.response.status === 400 && typeof (error.response.data) === 'object') {
                  let fields_errors = error.response.data;
                  this.setState({fields_errors});
                  if (error.response.data.hasOwnProperty('non_field_errors')) {
                     let form_errors = [...this.state.form_errors, ...error.response.data.non_field_errors];
                     this.setState({form_errors});
                  }
               } else {
                  let form_errors = [...this.state.form_errors, String(error.response.data)];
                  this.setState({form_errors});
               }
            } else if (error.request) { // The request was made but no response was received
               let form_errors = [...this.state.form_errors, error.request];
               this.setState({form_errors});
            } else {// Error on setting up the request
               let form_errors = [...this.state.form_errors, error.message];
               this.setState({form_errors});
            }
         });
   };

   /**
    * Check errors for form field
    * @param {Object} element - The object representing form field
    * @param {string} element.name - The name of the form field input
    * @param {string} element.value - The value of the form field input
    * @param {boolean} element.required - The required attribute of the form field input
    */
   checkFieldErrors = (element) => {
      let {name, value} = element;
      let fields_errors = {...this.state.fields_errors, [name]: []};
      if (element.required && !value) {
         fields_errors[name] = [this.props.t('this_field_may_not_be_blank')];
      }
      this.setState({fields_errors})
   }

   handleChange = (e) => {
      let {name, value} = e.target;
      this.checkFieldErrors(e.target);
      if (e.target.type === "checkbox") {
         name = e.target.name;
         value = this.state.formFields.hasOwnProperty(name) ? this.state.formFields[name] : [];
         value = value.filter(item => item !== e.target.value);
         if (e.target.checked) value.push(e.target.value);
      }
      const formFields = {...this.state.formFields, [name]: value};
      this.setState({formFields});
   };

   onBlur = (e) => {
      this.checkFieldErrors(e.target);
   };

   renderFieldError = (field_name) => {
      let fields_errors = [];
      if (this.state.fields_errors.hasOwnProperty(field_name)) {
         fields_errors = this.state.fields_errors[field_name];
      }
      return fields_errors.map((item, id) => (
         <p key={id} className="error">{item}</p>
      ));
   }

   renderFormErrors = () => {
      let form_errors = this.state.form_errors;
      return form_errors.map((item, id) => (
         <p key={id} className="error">{item}</p>
      ))
   }

   render() {
      const {t} = this.props
      if (!this.props.show_form) return null;
      return (
         <Fragment>
            <Col sm={12}>
               <div className="checkout-left-box">
                  <h3>{t("apply_for_page.anketa_of_cadidate")}</h3>
                  <p>{t("apply_for_page.the_information_is_confidential")}</p>
                  <form>
                     <Row className="checkout-form">
                        <Col>{this.renderFormErrors()}</Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={6}>
                           <label htmlFor="email" className={"asterisk"}>{t("email")}</label>
                           <input
                              type="text"
                              name="email"
                              value={this.state.formFields.email ? this.state.formFields.email : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="email"
                              required
                           />
                           {this.renderFieldError('email')}
                        </Col>
                        <Col md={6}>
                           <label htmlFor="name23"
                                  className={"asterisk"}>{t("apply_for_page.name_and_surname")}</label>
                           <input
                              type="text"
                              name="name"
                              value={this.state.formFields.name ? this.state.formFields.name : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="name23"
                              required
                           />
                           {this.renderFieldError('name')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={6}>
                           <label htmlFor="phone" className={"asterisk"}>{t("car_booking.phn")}</label>
                           <input
                              type="text"
                              name="phone_number"
                              value={this.state.formFields.phone_number ? this.state.formFields.phone_number : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="phone"
                              required
                           />
                           {this.renderFieldError('phone_number')}
                        </Col>
                        <Col md={6}>
                           <label htmlFor="where_you_live"
                                  className={"asterisk"}>{t("apply_for_page.where_you_live")}</label>
                           <input
                              type="text"
                              name="where_you_live"
                              value={this.state.formFields.where_you_live ? this.state.formFields.where_you_live : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="where_you_live"
                              required
                           />
                           {this.renderFieldError('where_you_live')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={12} className={"choices"}>
                           <label className={"asterisk"}>{t("apply_for_page.events_visited")}</label>
                           <label htmlFor="events_visited_0"><input onChange={this.handleChange} type="radio"
                                                                    name="events_visited" id="events_visited_0"
                                                                    value={t('apply_for_page.no')}/>{t('apply_for_page.no')}
                           </label>
                           <label htmlFor="events_visited_1"><input onChange={this.handleChange} type="radio"
                                                                    name="events_visited" id="events_visited_1"
                                                                    value={t('apply_for_page.yes')}/>{t('apply_for_page.yes')}
                           </label>
                           {this.renderFieldError('events_visited')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={6}>
                           <label htmlFor="kind_of_business"
                                  className={"asterisk"}>{t("apply_for_page.kind_of_business")}</label>
                           <input
                              type="text"
                              name="kind_of_business"
                              value={this.state.formFields.kind_of_business ? this.state.formFields.kind_of_business : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="kind_of_business"
                              required
                           />
                           {this.renderFieldError('kind_of_business')}
                        </Col>
                        <Col md={6}>
                           <label htmlFor="websites_reference">{t("apply_for_page.websites_reference")}</label>
                           <input
                              type="text"
                              name="websites_reference"
                              value={this.state.formFields.websites_reference ? this.state.formFields.websites_reference : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="websites_reference"
                           />
                           {this.renderFieldError('websites_reference')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={6}>
                           <label htmlFor="collegues_number"
                                  className={"asterisk"}>{t("apply_for_page.collegues_number")}</label>
                           <input
                              type="text"
                              name="collegues_number"
                              value={this.state.formFields.collegues_number ? this.state.formFields.collegues_number : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="collegues_number"
                              required
                           />
                           {this.renderFieldError('collegues_number')}
                        </Col>
                        <Col md={6}>
                           <label htmlFor="year_turnover"
                                  className={"asterisk"}>{t("apply_for_page.year_turnover")}</label>
                           <input
                              type="text"
                              name="year_turnover"
                              value={this.state.formFields.year_turnover ? this.state.formFields.year_turnover : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="year_turnover"
                              required
                           />
                           {this.renderFieldError('year_turnover')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={6}>
                           <label htmlFor="other_experience"
                                  className={"asterisk"}>{t("apply_for_page.other_experience")}</label>
                           <input
                              type="text"
                              name="other_experience"
                              value={this.state.formFields.other_experience ? this.state.formFields.other_experience : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="other_experience"
                              required
                           />
                           {this.renderFieldError('other_experience')}
                        </Col>
                        <Col md={6}>
                           <label htmlFor="hobby">{t("apply_for_page.hobby")}</label>
                           <input
                              type="text"
                              name="hobby"
                              value={this.state.formFields.hobby ? this.state.formFields.hobby : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="hobby"
                           />
                           {this.renderFieldError('hobby')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={6}>
                           <label htmlFor="business_goal"
                                  className={"asterisk"}>{t("apply_for_page.business_goal")}</label>
                           <input
                              type="text"
                              name="business_goal"
                              value={this.state.formFields.business_goal ? this.state.formFields.business_goal : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="business_goal"
                              required
                           />
                           {this.renderFieldError('business_goal')}
                        </Col>
                        <Col md={6}>
                           <label htmlFor="actual_problems">{t("apply_for_page.actual_problems")}</label>
                           <input
                              type="text"
                              name="actual_problems"
                              value={this.state.formFields.actual_problems ? this.state.formFields.actual_problems : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="actual_problems"
                           />
                           {this.renderFieldError('actual_problems')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col sm={12}>
                           <label htmlFor="request_for_mentor">{t("apply_for_page.request_for_mentor")}</label>
                           <input
                              type="text"
                              name="request_for_mentor"
                              value={this.state.formFields.request_for_mentor ? this.state.formFields.request_for_mentor : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="request_for_mentor"
                           />
                           {this.renderFieldError('request_for_mentor')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col sm={12}>
                           <label htmlFor="expectations_from_club"
                                  className={"asterisk"}>{t("apply_for_page.expectations_from_club")}</label>
                           <input
                              type="text"
                              name="expectations_from_club"
                              value={this.state.formFields.expectations_from_club ? this.state.formFields.expectations_from_club : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="expectations_from_club"
                              required
                           />
                           {this.renderFieldError('expectations_from_club')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col sm={12}>
                           <label htmlFor="your_skills"
                                  className={"asterisk"}>{t("apply_for_page.your_skills")}</label>
                           <input
                              type="text"
                              name="your_skills"
                              value={this.state.formFields.your_skills ? this.state.formFields.your_skills : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="your_skills"
                              required
                           />
                           {this.renderFieldError('your_skills')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={12} className={"choices"}>
                           <label
                              className={"asterisk"}>{t("apply_for_page.participation_in_common_projects")}</label>
                           <label htmlFor="part0"><input onChange={this.handleChange} type="checkbox"
                                                         name="participation" id="part0"
                                                         value='0'/>{t('apply_for_page.no')}</label>
                           <label htmlFor="part1"><input onChange={this.handleChange} type="checkbox"
                                                         name="participation" id="part1"
                                                         value='1'/>{t('apply_for_page.yes_as_investor')}
                           </label>
                           <label htmlFor="part2"><input onChange={this.handleChange} type="checkbox"
                                                         name="participation" id="part2"
                                                         value='2'/>{t('apply_for_page.yes_as_partner')}
                           </label>
                           <label htmlFor="part3"><input onChange={this.handleChange} type="checkbox"
                                                         name="participation" id="part3"
                                                         value='3'/>{t('apply_for_page.want_investments')}
                           </label>
                           <label htmlFor="part4"><input onChange={this.handleChange} type="checkbox"
                                                         name="participation" id="part4"
                                                         value='4'/>{t('apply_for_page.have_startup')}</label>
                           <label htmlFor="part5">
                              <input onChange={this.handleChange} type="checkbox" name="participation"
                                     id="part5" value='5'/>{t('apply_for_page.other')}
                              <input
                                 onChange={this.handleChange}
                                 type="text"
                                 name="participation_other"
                                 value={this.state.formFields.participation_other ? this.state.formFields.participation_other : ''}/>
                           </label>
                           {this.renderFieldError('participation')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col sm={12} md={6}>
                           <label htmlFor="telegram_nick">{t("apply_for_page.telegram_nick")}</label>
                           <input
                              type="text"
                              name="telegram_nick"
                              value={this.state.formFields.telegram_nick ? this.state.formFields.telegram_nick : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="telegram_nick"
                           />
                           {this.renderFieldError('telegram_nick')}
                        </Col>
                        <Col sm={12} md={6}>
                           <label htmlFor="your_instagramm">{t("apply_for_page.your_instagramm")}</label>
                           <input
                              type="text"
                              name="your_instagramm"
                              value={this.state.formFields.your_instagramm ? this.state.formFields.your_instagramm : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="your_instagramm"
                           />
                           {this.renderFieldError('your_instagramm')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col sm={12} md={6}>
                           <label htmlFor="your_fb">{t("apply_for_page.your_fb")}</label>
                           <input
                              type="text"
                              name="your_fb"
                              value={this.state.formFields.your_fb ? this.state.formFields.your_fb : ''}
                              onChange={this.handleChange}
                              onBlur={this.onBlur}
                              id="your_fb"
                           />
                           {this.renderFieldError('your_fb')}
                        </Col>
                     </Row>
                     <Row className="checkout-form">
                        <Col md={12} className={"choices"}>
                           <label
                              className={"asterisk"}>{t("apply_for_page.personal_data_agreement")}</label>
                           <label htmlFor="personal_data_agreement">
                              <input onChange={this.handleChange}
                                     type="checkbox"
                                     name="personal_data_agreement"
                                     id="personal_data_agreement"
                                     value='0'/>
                              {t('apply_for_page.agree')}</label>
                           {this.renderFieldError('personal_data_agreement')}
                        </Col>
                     </Row>
                  </form>
               </div>
            </Col>
            <Col sm={12}>
               <div className="booking-right">
                  <div className="action-btn">
                     <Link to="/" onClick={(e) => this.handleSubmit(e, this.state.formFields)}
                           className="gauto-btn">
                        {t("send")}
                     </Link>
                  </div>
               </div>
            </Col>
         </Fragment>
      )
   }
}



export default ApplyForForm
