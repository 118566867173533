import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from "react-bootstrap";
import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import Footer from "../../components/Footer";

const InDevPage = () => {
    const {t} = useTranslation();
    return (
        <Fragment>
            <Header/>
            <PageTitle
                pageTitle={t("header-navigation.page_in_dev")}
                pagesub={t("header-navigation.page_in_dev")}
            />
            <section className="section_70">
                <Container>
                    <Row><Col sm={12}><h1>{t("header-navigation.page_in_dev")}</h1></Col></Row>
                </Container>
            </section>
            <Footer/>
        </Fragment>
    );
};
export default InDevPage;
