import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from "react-bootstrap";
import {
   FaFacebookF,
   FaTwitter,
   FaLinkedinIn,
   FaInstagram,
} from "react-icons/fa";

import img1 from "../../img/meeting1.jpg";


const Meetings = () => {
   const {t} = useTranslation();

   return (
      <section className="meetings-area section_70">
         <Container>
            <Row>
               <Col md={12}>
                  <img src={img1} alt="Meeting 1"/>
               </Col>
            </Row>
         </Container>
      </section>
   );
};

export default Meetings;
