import React, {Fragment} from "react";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import Forum from "../../components/Forum"


const ForumPage = () => {
   return (
      <Fragment>
         <Header/>
         <Forum />
         <Footer/>
      </Fragment>
   );
};
export default ForumPage;
