import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from "react-bootstrap";

import img1 from "../../img/400x213.jpg";

const Promo = () => {
   const {t} = useTranslation();

   return (
      <>
         <section className="gauto-promo-area">
            <Container>
               <Row className="align-items-center">
                  <Col md="6">
                     <div className="promo-box-left">
                        <img src={img1} alt="promo car"/>
                     </div>
                  </Col>
                  <Col md="6">
                     <div className="promo-box-right">
                        <h3>{t("promo_text")}<br/>{t('promo_text_conditions')}</h3>
                        <ul>
                           <li>1 {t('entry_condition_1')}</li>
                           <li>2 {t('entry_condition_2')}</li>
                           <li>3 {t('entry_condition_3')}</li>
                           <li>4 {t('entry_condition_4')}</li>
                           <li>5 {t('entry_condition_5')}</li>
                        </ul>
                        <Link to="/apply-for" className="gauto-btn">
                           {t("promo_btn")}
                        </Link>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   );
};

export default Promo;
