import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from "react-bootstrap";
import {
   FaFacebookF,
   FaTwitter,
   FaLinkedinIn,
   FaInstagram,
} from "react-icons/fa";


const PartnerOffers = () => {
   const {t} = useTranslation();

   const onClick = (e) => {
      e.preventDefault();
   };

   return (
      <section className="partner-offers-area section_70">
         <Container>
            <Row className="mb-5">
               <Col md={12}>
                  <div className="site-heading">
                     <h2>{t("what_get_participants")}</h2>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>01</span> БІЗНЕС-ІВЕНТИ</div>
                     <hr className='devider'/>
                     <div className="description">
                        Кожен місяць, 20+ можливостей для розвитку вашого бізнесу! Виберіть свій формат і приєднуйтесь
                        до нашої бізнес-дiаспоры
                     </div>
                  </div>
               </Col>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>02</span> ТЕЛЕГРАМ-ЧАТИ</div>
                     <hr className='devider'/>
                     <div className="description">
                        Загальний чат. Групові розмови та підгрупи за спеціалізацією - все для вашого успіху!
                     </div>
                  </div>
               </Col>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>03</span> ЗУСТРІЧІ РОДИНАМИ</div>
                     <hr className='devider'/>
                     <div className="description">
                        Family Days - час для створення спогадів, зарядження енергією та насолоди життям разом з
                        близькими!
                     </div>
                  </div>
               </Col>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>04</span> АКТИВНОСТІ</div>
                     <hr className='devider'/>
                     <div className="description">
                        Завжди можна вибрати свою активність: гольф, шахи, покер, біг, теніс, падл та багато іншого.
                     </div>
                  </div>
               </Col>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>05</span> КОРПОРАТИВНІ ЕКСКУРСІЇ</div>
                     <hr className='devider'/>
                     <div className="description">
                        Дізнайтеся більше про внутрішні процеси та успішні стратегії компаній на ексклюзивних
                        корпоративних турах!
                     </div>
                  </div>
               </Col>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>06</span> ОНЛАЙН ЗУСТРІЧІ</div>
                     <hr className='devider'/>
                     <div className="description">
                        Хочете зустрітися з найвідомішими бізнесменами світу та дізнатися їхні секрети успіху? Наші
                        онлайн зустрічі – це унікальна можливість для вас!
                     </div>
                  </div>
               </Col>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>07</span> КЛУБНА КАРТА</div>
                     <hr className='devider'/>
                     <div className="description">
                        Ми раді представити тобі нашу клубну карту, яка дарує скидки на готелі, ресторани, магазини та
                        послуги. Так що не втрачай часу та приєднуйся до нашої команди!
                     </div>
                  </div>
               </Col>
               <Col className="mb-4 mb-md-5" lg={3} sm={12}>
                  <div className="partner-offers--offer">
                     <div className="title"><span>08</span> ВНУТРІШНІ КЛУБИ</div>
                     <hr className='devider'/>
                     <div className="description">
                        Ми запрошуємо тебе до наших внутрішніх клубів, де ти зможеш об'єднатися з експертами в галузях
                        міжнародної торгівлі, ІТ, маркетингу, логістики, інвестицій, юриспруденції та будівництва.
                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
      </section>
   );
};

export default PartnerOffers;
