import React, {useEffect} from "react";
import forum from "../../../img/forum.jpg"
import forum_invite_1 from "../../../img/forum-invite-1.jpg"
import ButtonForEvent from "./../button";
import Slice1 from '../../../img/forum-mobile/Slice1.jpg'
import Slice2Button from '../../../img/forum-mobile/Slice2-button.jpg'
import Slice3 from '../../../img/forum-mobile/Slice3.jpg'
import Slice4Button from '../../../img/forum-mobile/Slice4-button.jpg'
import Slice5 from '../../../img/forum-mobile/Slice5.jpg'
import Slice6Button from '../../../img/forum-mobile/Slice6-button.jpg'
import Slice7 from '../../../img/forum-mobile/Slice7.jpg'
import Slice8Button from '../../../img/forum-mobile/Slice8-button.jpg'
import Slice9 from '../../../img/forum-mobile/Slice9.jpg'
import {initTicketForEvent} from "../../ticketforevent";


const Event2023October01 = () => {

   useEffect(() => {
      initTicketForEvent();
   }, []);

   return (
      <>
         <section className="section_70 forum">
            <div className="banner-wrapper">
               <img src={forum} alt=""/>
            </div>
            <div className="btn-wrapper">
               <div className="btn-wrapper__contacts">
                  Відповімо<br/> на всі ваші запитання<br/><strong>+34 613 705 442</strong>
               </div>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={forum_invite_1} alt=""/>
            </div>
         </section>
         <section className="section_70 forum mobile">
            <div className="forum-invite-wrapper">
               <img src={Slice1} alt=""/>
            </div>
            <div className="btn-wrapper-mobile">
               <img src={Slice2Button} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={Slice3} alt=""/>
            </div>
            <div className="btn-wrapper-mobile">
               <img src={Slice4Button} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={Slice5} alt=""/>
            </div>
            <div className="btn-wrapper-mobile">
               <img src={Slice6Button} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={Slice7} alt=""/>
            </div>
            <div className="btn-wrapper-mobile">
               <img src={Slice8Button} alt=""/>
               <ButtonForEvent/>
            </div>
            <div className="forum-invite-wrapper">
               <img src={Slice9} alt=""/>
            </div>
         </section>
      </>
   );
};

export default Event2023October01;
